* {
	margin: 0;
	padding: 0;
}

html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	color: $text-color;
}

h1,
h2,
h3,
h4 {
	color: $text-color;
	font-weight: 400;
	margin-bottom: 16px;
}

a {
	color: $link-color;
	text-decoration: none;

	&.muted {
		color: $text-color;
	}

	&.mention {
		cursor: pointer;
	}
}

p {
	line-height: 24px;
	letter-spacing: normal;
	margin-bottom: 12px;

	a:hover {
		text-decoration: underline;
	}

	code {
		background: #111111;
		padding: 0.15rem 0.4rem;
		color: #aa759f;
		border-radius: 3px;
		box-shadow: inset 0 1px 10px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 0.1), 0 -1px 0 rgba(0, 0, 0, 0.5);
	}
}

ul,
ol {
	padding-left: 32px;
	padding-bottom: 16px;
}

blockquote {
	padding: 0 1em;
	color: #968f83;
	border-left: 0.25em solid $text-color;
}

table {
	display: block;
	width: 100%;
	overflow: auto;
	margin-top: 0;
	margin-bottom: 16px;
	border-spacing: 0;
	border-collapse: collapse;
	border-color: #52595b;
}

table th,
table td {
	padding: 6px 13px;
	border: 1px solid rgb(57, 61, 63);
}

table th {
	font-weight: 600;
}

pre {
	padding: 16px;
	overflow: auto;
	font-size: 85%;
	line-height: 1.45;
	box-shadow: inset 0 1px 10px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 0.1), 0 -1px 0 rgba(0, 0, 0, 0.5);
	background: #111111;
	border-radius: 6px;
	margin-bottom: 16px;
}

video {
	width: 100%;
	height: auto;
	outline: none;
}

audio {
	outline: none;
}

::-webkit-scrollbar {
	background-color: #232627;
	color: #a19a90;
}

::-webkit-scrollbar-corner {
	background-color: #1c1e1f;
}

::-webkit-scrollbar-thumb {
	background-color: #45494c;
}

markdown {
	word-break: break-word;

	img {
		max-width: 100%;
		display: block;
	}

	.checkbox-ul {
		padding-left: 0;
		padding-bottom: 0;
	}

	.checkbox-li {
		list-style: none;
		position: relative;
		padding-left: 28px;
		min-height: 22px;
		margin-bottom: 12px;

		& > input {
			position: absolute;
			top: 3px;
			left: 8px;
		}
	}
}

.post markdown,
.preview markdown {
	ul:not(.checkbox-ul),
	ol:not(.checkbox-ul) {
		padding-left: 29px;
	}
}

.comment markdown > p:last-of-type {
	margin-bottom: 0;
}
