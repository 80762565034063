@use '@angular/material' as mat;
@include mat.core();

@import '@angular/material/theming';
@import '@angular/cdk/overlay-prebuilt.css';
@import 'normalize.css/normalize.css';
@import './variables';
@import './typography';
@import './mat-icon-button-size.mixin.scss';

$custom-typography: mat.define-typography-config(
	$headline-5: mat.define-typography-level(32px, 48px, 400),
	$headline-6: mat.define-typography-level(24px, 32px, 400),
	$subtitle-1: mat.define-typography-level(20px, 28px, 400),
	$body-2: mat.define-typography-level(16px, 24px, 400),
);


$dark-theme: mat.define-dark-theme(
	(
		color: (
			primary:  mat.define-palette(mat.$blue-palette),
			accent: mat.define-palette(mat.$pink-palette, A200, A100, A400),
			warn: mat.define-palette(mat.$red-palette),
		),
		typography:  $custom-typography,
		density: 0,
	)
);

@include mat.all-component-themes($dark-theme);
// should be last after theme is build
@import './theme-overrides';
@include MatIconButtonSize('small', 30px, 20px);

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	margin-bottom: 1rem;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.role {
	display: inline-block;
	padding: 0 7px;
	font-size: 13px;
	font-weight: 500;
	line-height: 18px;
	border-radius: 2em;
	border: 1px solid rgba(255, 255, 255, 0.51);
	margin-left: 7px;
	color: rgba(255, 255, 255, 0.51);

	&.admin {
		border-color: #aa0000;
	}

	&.op {
		border-color: #00aa00;
	}
}

.grecaptcha-badge {
	visibility: hidden;
}
