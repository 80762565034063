// colors
$background-color: #2b2b2b;
$ink-bar-color: #2196f3;
$text-color: #dadada;
$link-color: #49a0cc;
$text-color-dark: darken($text-color, 20%);
$gradient-background: linear-gradient(90deg, rgba(36, 36, 36, 1) 0%, rgba(23, 25, 28, 1) 100%);
$primary-icon-color: #A7A7A7;
$tab-border: 1px solid rgba(255, 255, 255, 0.12);

// sizes
$avatar-border: 2px;
