.mat-mdc-snack-bar-container {
	--mat-mdc-snack-bar-button-color: #ff4081;
	--mdc-snackbar-container-color: #323232;
	--mdc-snackbar-supporting-text-color: $text-color;


	&.info .mdc-snackbar__label {
		color: $text-color;
	}

	&.error .mdc-snackbar__label {
		color: #f44336;
	}

	&.success .mdc-snackbar__label {
		color: #00ce00;
	}
}

mat-progress-bar .mdc-linear-progress__bar-inner {
	border-color: #bbdefb;
}

gw-add-item .mat-mdc-tab-header,
gw-profile .mat-tab-header {
	background: $background-color;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

gw-home {
	.mat-drawer-backdrop.mat-drawer-shown {
		background-color: transparent;
	}
}

.backdrop {
	opacity: 0;
}

@media screen and (max-width: 500px) {
	gw-post,
	gw-comment,
	gw-add-item {
		.mat-mdc-card {
			padding: 16px 10px;
			margin-bottom: 10px !important;
		}
	}
}

.mat-mdc-card,
.mat-mdc-menu-panel,
.mdc-menu-surface,
.mat-mdc-dialog-content.mdc-dialog__content,
.mat-mdc-table,
.mat-expansion-panel,
.mat-mdc-dialog-actions {
	background: $background-color !important;
}

.mat-mdc-card {
	padding: 16px;
	margin-bottom: 16px;
}

// icons inside mat-button
.mat-mdc-button > .mat-icon {
	margin-right: 0 !important;
	font-size: 1.5rem !important;
	height: 1.5rem !important;
	width: 1.5rem !important;
}

.mat-drawer-container,
.mdc-list-item__primary-text,
.mat-mdc-menu-item .mat-icon-no-color,
.mat-mdc-menu-submenu-icon,
.mat-icon,
.mat-mdc-select-value,
.mdc-data-table__pagination-total,
.mdc-data-table__pagination-rows-per-page-label,
.mdc-data-table__cell,
.mdc-dialog__content p,
.mat-expansion-panel,
.mat-expansion-panel-header-title,
.mdc-form-field label {
	color: $text-color;
}

// input or selector
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
	background: rgba(255, 255, 255, 0.055);
}

// clicked on input or selector
.mat-mdc-form-field-focus-overlay {
	background-color: rgba(255, 255, 255, 0.24);
}

gw-category .mdc-list-item__primary-text {
	font-size: 1rem;
}
